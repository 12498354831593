import toast, { Toaster } from 'react-hot-toast';


<Toaster
  position="top-center"
  reverseOrder={false}
/>

export const alertSuccess = (text) => {
    toast.success(text)
}

export const alertError = (text) => {

    toast.error(text)
}

export const alertPromise = (textSave, textError, promise, textLoading = 'Salvando...') => {
    toast.promise(
        promise,
        {
            loading: textLoading,
            success: textSave,
            error: e => (e && e.message) || textError,
        }
    );
}


export const ToastComponent = () => {
    return <Toaster
        containerStyle={{
            zIndex: 214748290
        }}
    />
}