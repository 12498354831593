const translation = {
    'Invalid login credentials': "Email ou senha inválidos!",
    'For security purposes, you can only request this once every 60 seconds': "Por motivos de segurança, aguarde 60 segundos para realizar um novo acesso!",
    'Signups not allowed for otp': "Email não cadastrado!",
    'You must provide either an email, phone number, a third-party provider or OpenID Connect.' : "Você deve inserir um email e senha válidos",
    'Password should be at least 6 characters' : "A senha precisa ter no mínimo 6 caracteres"
}

export const getTranslation = (text) => {
    return translation[text] || text;
}