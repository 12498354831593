import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export class ReducerGenerator {
    constructor(name, usePersist, initial = {}) {
        this.name = name;
        this.initial = initial;
        this.persistConfig = usePersist && { key: name, storage };
        this.actions = {
            [`SET_${name.toUpperCase()}`]: `SET_${name.toUpperCase()}`,
            [`CLEAR_${name.toUpperCase()}`]: `CLEAR_${name.toUpperCase()}`
        };
    }

    getReducerNormal() {
        return (state = this.initial, action) => {
            switch (action.type) {
                case this.actions[`SET_${this.name.toUpperCase()}`]:
                    return { ...state, ...action.payload };
                case this.actions[`CLEAR_${this.name.toUpperCase()}`]:
                    return this.initial;
                default:
                    return state;
            }
        };
    }

    getReducerPersist() {
        return persistReducer(this.persistConfig, this.getReducerNormal())
    }

    getReducer() {
        if (this.persistConfig) {
            return this.getReducerPersist();
        } else {
            return this.getReducerNormal();
        }

    }

    getActions() {
        return this.actions;
    }

    setData(payload) {
        if (!this.store) {
            throw new Error("Store precisa ser iniciada!")
        }

        this.store.dispatch({
            type: this.actions[`SET_${this.name.toUpperCase()}`], payload
        })
    }

    clearData() {
        this.store.dispatch({
            type: this.actions[`CLEAR_${this.name.toUpperCase()}`]
        })
    }

    setStore(store) {
        this.store = store
    }
}