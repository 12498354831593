import { combineReducers } from 'redux';
import { persistReducer } from "redux-persist";
import storage from 'redux-persist/lib/storage';
import app from './app/Reducers';
import { ReducerGenerator } from './utils-redux';

export const formStorage = new ReducerGenerator("form", true)
const appConfig = {
    key: 'app',
    storage,
    blacklist: ["loaded", "edit"]
};

export const Reducers = combineReducers({
    app: persistReducer(appConfig, app),
    form: formStorage.getReducer()

})


export const setStore = (store) => {
	formStorage.setStore(store)
}