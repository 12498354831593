import React from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useSidebarControls } from '../../core/hooks/sidebar';
import { SidebarScreen } from '../../screens/Sidebar/SidebarScreen';

export const SidebarContainer = (props) => {
    const location = useLocation()
    const { pathname } = location
    const { collapsed, setCollapsed } = useSidebarControls();
    const company = useSelector(state => state.app.company || {})
    const loggedUser = useSelector(state => state.app.user || {})

    return (
        <>
            <SidebarScreen
                pathname={pathname}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                loggedUser={loggedUser}
                company={company}
            />
        </>
    )
}